import { useNavigate } from 'react-router-dom'
import { Cards, Link, Header, Box } from '@cloudscape-design/components'

const awsCards = [
	{
		name: 'Accounts',
		description: 'You can manage accounts here.',
		href: '/accounts'
	},
	{
		name: 'Projects',
		description: 'You can manage project creation here.',
		href: '/projects'
	},
	{
		name: 'Ephemeral',
		description: 'You can request for ephemeral project creation here.',
		href: '/projects/ephemeral'
	},
	{
		name: 'Access Management',
		description: 'You can manage users and roles here.',
		href: '/access-management'
	}
]

const gcpCards = [
	{
		name: 'Projects',
		description: 'You can manage GCP projects here.',
		href: '/gcpProjects'
	},
	{
		name: 'Platforms',
		description: 'You can manage GCP projects here.',
		href: '/platforms'
	},
	{
		name: 'Applications',
		description: 'You can manage GCP projects here.',
		href: '/applications'
	}
]

const rhocpCards = [
	{
		name: 'Tenants',
		description: 'You can manage RHOCP tenants here.',
		href: '/rhocpTenants'
	}
]

const Dashboard = () => {
	const navigate = useNavigate()

	return (
		<Box>
			<Box margin={{ bottom: 'l' }}>
				<Header variant="h1">AWS</Header>
			</Box>
			<Cards
				items={awsCards}
				cardDefinition={{
					header: (item) => (
						<Link fontSize="heading-m" onFollow={() => navigate(item.href)}>
							{item.name}
						</Link>
					),
					sections: [
						{
							header: 'Description:',
							content: (item) => item.description
						}
					]
				}}
			></Cards>

			<Box margin={{ bottom: 'l' }}>
				<Header variant="h1">GCP</Header>
			</Box>
			<Cards
				items={gcpCards}
				cardDefinition={{
					header: (item) => (
						<Link fontSize="heading-m" onFollow={() => navigate(item.href)}>
							{item.name}
						</Link>
					),
					sections: [
						{
							header: 'Description:',
							content: (item) => item.description
						}
					]
				}}
			></Cards>

			<Box margin={{ bottom: 'l' }}>
				<Header variant="h1">RHOCP</Header>
			</Box>
			<Cards
				items={rhocpCards}
				cardDefinition={{
					header: (item) => (
						<Link fontSize="heading-m" onFollow={() => navigate(item.href)}>
							{item.name}
						</Link>
					),
					sections: [
						{
							header: 'Description:',
							content: (item) => item.description
						}
					]
				}}
			></Cards>
		</Box>
	)
}

export default Dashboard