import CONSTANTS from "../../constants";
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader";

const { RHOCPTENANTS_API_URL } = CONSTANTS

const getSingleRhocpTenant = async(rhocpTenantID: string): Promise<{status: number, body: Array<object>}> => {
  const response = await fetchWithAuthorizationHeader(`${RHOCPTENANTS_API_URL}?rhocpTenantID=${rhocpTenantID}`)
  if (!response.ok) {
    return {
      status: response.status,
      body: []
    }
  }

  return response.json()
}

export default getSingleRhocpTenant