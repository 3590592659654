import {
	Box,
	Button,
	Container,
	Form,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FormInput from '../../../../components/ControlledFormInputs/Input'
import type { EphemeralRequest } from '../../../../types/projects'
import { useEphemeralFormStore } from '../../../../stores/projects/ephemeralFormStore'
import useCreateNewProject from '../../../../hooks/mutate/useCreateNewProject'
import FormSelect from '../../../../components/ControlledFormInputs/Select'
import useProjects from '../../../../hooks/query/useProjects'

const EditEphemeralForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { data: Project } = useProjects()
	const [ProjectOptions, setProjectOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const [WIPOptions, setWIPOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const [EnvOptions, setEnvOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const getRandomNumber = () => {
		let number
		do {
		number = Math.floor(Math.random() * 100).toString().padStart(2, '0')
		} while (number === '00')
		return number
	}
	function getExistingEphNumbers(appName: string) {
		let twoDigitNumbers = []

		if (Project) {
			for (let i = 0; i < Project.length; i++) {
				const details = Project[i].details
				if (details.isephemeral){
					if (details.projectName === appName) {
						if (
							details.ephemeralEnv &&
							/\d{2}$/.test(details.ephemeralEnv)
						) {
							const twoDigits = details.ephemeralEnv.slice(-2)
							twoDigitNumbers.push(twoDigits)
						}
					}
				}
			}
		}

		return twoDigitNumbers
	}
	const generateCustomProjectID = () => {
		const randomDigits = Math.floor(100000000 + Math.random() * 900000000)
		return `EPH${randomDigits}`;
	}
	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useEphemeralFormStore()

	const { mutate: createNewProject, isLoading } = useCreateNewProject()

	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})
	useEffect(() => {
		const fetchData = async () => {
		  if (Project && Project.length > 0) {
			const filteredProjects = Project.filter(
			  (project) => project.status === 'APPROVED'
			)	  
			const projectNames = filteredProjects
			  .map((project) => project.details.platformName?.toLowerCase())
			  .filter((name) => name !== undefined)
	  
			const Options = Array.from(new Set(projectNames))
			const ProjectOptions = Options.map((project) => ({
				label: `atlas-project-${project}`,
				value: `atlas-project-${project}`,
			  }))
			setProjectOptions(ProjectOptions)
		  }
		} 
		fetchData()
	  },[setProjectOptions,Project])
	
	  useEffect(() => {
		const fetchData = async () => {
		  if (Project && Project.length > 0) {
			const filteredProjects = Project.filter(
			  (project) => project.status === 'APPROVED' && 
			  			project.details.platformName === (formValues.project?.value?.replace('atlas-project-', '') || '')
			)	  
			const wip = filteredProjects
			  .map((wip) => wip.details.projectName?.toLowerCase())
			  .filter((name) => name !== undefined)
			const WIPOptions = wip.map((project) => ({
			label: `atlas-${project}`,
			value: `atlas-${project}`,
			}))
			setWIPOptions(WIPOptions)
		  }
		} 
		fetchData()
	  },[setWIPOptions,formValues.project?.value,Project])

	  useEffect(() => {
		const fetchData = async () => {
		  if (Project && Project.length > 0) {
			const filteredProjects = Project.filter(
			  (project) => project.status === 'APPROVED' &&
			  			project.details.platformName === (formValues.project?.value?.replace('atlas-project-', '') || '') &&
                        project.details.projectName === (formValues.workload?.value?.replace('atlas-', '') || '') 
			)
			const env = filteredProjects
			  .map((env) => env.details.environment.value || env.details.environment)
			  .filter((name) => name !== undefined)
			const EnvOptions = env.map((env) => ({
			label: env,
			value: env,
			}))
			setEnvOptions(EnvOptions)
		  }
		} 
		fetchData()
	  },[setEnvOptions,formValues.workload?.value,formValues.project?.value,Project])

	  useEffect(() => {
		const fetchData = async () => {
		  if (Project && Project.length > 0) {
			const filteredProjects = Project.filter(
				(project) =>
				  project.status === 'APPROVED' &&
				  project.details.platformName === (formValues.project?.value?.replace('atlas-project-', '') || '') &&
				  project.details.projectName === (formValues.workload?.value?.replace('atlas-', '') || '') &&
				  (
					typeof project.details.environment === 'object'
					  ? project.details.environment.value === formValues.environment?.value
					  : project.details.environment === formValues.environment?.value
				  )
			  )			   
			const awsAccount = filteredProjects.find(
				(project) => project.details.awsAccountID !== undefined
			)?.details.awsAccountID
			setValue('awsAccountID', awsAccount)
			setFormValues('awsAccountID', awsAccount)
		  }
		} 
		fetchData()
	  },[setValue,setFormValues,formValues.environment?.value,formValues.workload?.value,formValues.project?.value,Project])

	  useEffect(() => {
		let existingEphemeralNumbers = getExistingEphNumbers(
			formValues.workload?.value?.replace('atlas-', '') || ''
		)
		let randomNumber = getRandomNumber()

		while (existingEphemeralNumbers.includes(randomNumber)) {
			randomNumber = getRandomNumber()
		}
		const EphemeralEnvName = "e" + formValues.environment.value + randomNumber
		setValue('ephemeralEnv', EphemeralEnvName)
		setFormValues('ephemeralEnv', EphemeralEnvName)
	}, [setValue,setFormValues,formValues.environment.value])

	useEffect(() => {
        const customProjectID = generateCustomProjectID()
        setValue('customProjectID', customProjectID)
		setFormValues('customProjectID', customProjectID)
    }, [setFormValues,setValue])

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return
		const ephemeral: EphemeralRequest = {
			requestorID: thisUserEmail,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			isephemeral: true,
			creationTime: new Date().toISOString()
		}
		createNewProject(ephemeral, {
			onSuccess: () => {
				navigate('/projects')
				clearFormValues()
			}
		})
	}

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Request New Ephemeral Project
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<FormSelect
							label="Project Name"
							name="project"
							control={control}
							rules={{
								required: 'Select Project'
							}}
							setFormValues={setFormValues}
							options={ProjectOptions}
							placeholder="Select Project"
						/>
						<FormSelect
							label="Workload"
							name="workload"
							control={control}
							rules={{
								required: 'Select Workload'
							}}
							setFormValues={setFormValues}
							options={WIPOptions}
							placeholder="Select Workload"
						/>
						<FormSelect
                            label="Environment"
                            name="environment"
                            control={control}
                            rules={{
                                required: 'Please Select Option'
                            }}
                            setFormValues={setFormValues}
                            options={EnvOptions}
                            placeholder="Select Environment"
                        />
                        <FormInput
							name="awsAccountID"
							label="AWS Account ID"
							placeholder='Enter AWS Account ID'
							type="number"
							control={control}
							setFormValues={setFormValues}
							disabled={true}
						/>
                        <FormInput
							name="ephemeralEnv"
							label="Ephemeral Environment"
							type="text"
							control={control}
							setFormValues={setFormValues}
							disabled={true}
						/>
						<FormInput
							name="customProjectID"
							label="Project ID"
							description="12 alphanumeric characters only"
							type="text"
							control={control}
							setFormValues={setFormValues}
							disabled={true}
						/>
                        <FormInput
                            name="teardown_after_days"
                            label="Teardown After Days"
                            type="text"
                            control={control}
                            rules={{
                                required: 'Please enter a valid no.of Days',
                                pattern: {
                                    value: /^\d{1,2}$/,
                                    message: 'Minimum 1 Day Required'
                                },
                                maxLength: {
                                    value: 2,
                                    message: 'Maximum 99 Days Allowed'
                                }
                            }}
                            placeholder="Enter Valid No.of Days"
                            setFormValues={setFormValues}
                        />
					</SpaceBetween>
				</Container>
			</SpaceBetween>
		</Form>
	)
}

export default EditEphemeralForm
