import { FC } from 'react'
import { KeyValuePairs } from '@aws-northstar/ui'
import { CSSProperties } from 'react';
import {
	Box,
	ColumnLayout,
	Container,
	ExpandableSection,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import CONSTANTS from '../../../constants';

type AccessDetailsProps = {
	platformName: string
	env: string
}

const AccessDetails: React.FC<AccessDetailsProps> = ({
	platformName,
	env
}) => {
	const baseUrl = 'https://gitlab.com/globetelecom/platforms/'
	let curEnv = env
	if (env === 'staging') {
		curEnv = 'stg'
	}
	const generalDetailsKeyValuePairsItems = [
		[
			{
				label: '',
				value: (
					<a
						href={`${baseUrl}${platformName}`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Gitlab Platform SubGroup`}
					</a>
				)

			}
		],
		[
			{
				label: '',
				value: (
					<a
						href={`https://ccm-argo.elz-gcp-${curEnv}-globetel.com/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Centralized ArgoCD`}
					</a>
				)
			}
		]
	]

	const compassLinkKeyValuePairsItems = [
		[
			{
				label: '',
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/essentials/atlas_101/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Atlas 101`}
					</a>
				)

			}
		],
		[
			{
				label: "",
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/essentials/meet_criteria/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Meet Criteria`}
					</a>
				)

			}
		],
		[
			{
				label: ' ',
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/developer-enablement/application-onboarding/application_onboarding/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Application onboarding`}
					</a>
				)

			}
		],

	]
	return (
		<Container header={<Header variant="h3">Access Details</Header>}>
			<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px' }}>
				For accesses to the links below, you may reach out to Platform
				Engineering group (isg-ceso-platengr@globe.com.ph)
			</p>
			<ColumnLayout columns={2} variant="text-grid">
				<ExpandableSection headerText="Resource Links">
				<ColumnLayout columns={2} variant="text-grid">
					<KeyValuePairs
						key={'generalDetails'}
						items={generalDetailsKeyValuePairsItems}
					/>
					</ColumnLayout>
				</ExpandableSection>
				<ExpandableSection headerText="What's Next?">
				<ColumnLayout columns={2} variant="text-grid">
					<KeyValuePairs
						key={''}
						items={compassLinkKeyValuePairsItems}
					/>
					</ColumnLayout>
				</ExpandableSection>
			</ColumnLayout>

		</Container>
	)
}

export default AccessDetails