import CONSTANTS from "../../constants"
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader"
import { RhocpTenantEditRequest } from "../../types/rhocpTenant"

const { RHOCPTENANTS_API_URL } = CONSTANTS

const editRhocpTenant = async(
  editRhocpTenant: RhocpTenantEditRequest
): Promise<any> => {
  const response = await fetchWithAuthorizationHeader(`${RHOCPTENANTS_API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      action: 'UPDATE'
    },
    body: JSON.stringify(editRhocpTenant)
  })

  return response.json()
}

export default editRhocpTenant