import React from 'react'
import { KeyValuePairs } from '@aws-northstar/ui'
import {
	ColumnLayout,
	Container,
	ExpandableSection,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import CONSTANTS from '../../../constants'

type AccessDetailsProps = {
	env: string
	platformName: string
	appName: string
}

const AccessDetails: React.FC<AccessDetailsProps> = ({
	env,
	platformName,
	appName
}) => {
	const baseUrl = 'https://gitlab.com/globetelecom/platforms/'

	let curEnv = env
	if (env === 'staging') {
		curEnv = 'stg'
	}

	const generalDetailsKeyValuePairsItems = [
		[
			{
				label: '',
				value: (
					<a
						href={`${baseUrl}${platformName}/application/${appName}`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Gitlab Application Subgroup`}
					</a>
				)
			}
		],
		[
			{
				label: '',
				value: (
					<a
						href={`${baseUrl}${platformName}/infrastructure/${appName}/gcp/peripheral-infra`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Gitlab Infrastructure Project`}
					</a>
				)
			}
		],

		[
			{
				label: '',
				value: (
					<a
						href={`${baseUrl}${platformName}/infrastructure/${appName}/gcp/argonaut-helm-pack`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Application Helm Chart Project`}
					</a>
				)
			}
		],
		[
			{
				label: '',
				value: (
					<a
						href={`https://ccm-argo.elz-gcp-${curEnv}-globetel.com/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Centralized AgroCD`}
					</a>
				)
			}
		]
	]

	const compassLinkKeyValuePairsItems = [
		[
			{
				label: '',
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/essentials/atlas_101/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Atlas 101`}
					</a>
				)
			}
		],
		[
			{
				label: '',
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/developer-enablement/microservice/onboarding_microservice_with_helm/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Microservices Onboarding`}
					</a>
				)
			}
		],
		[
			{
				label: '',
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/essentials/meet_criteria/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Meet Criteria`}
					</a>
				)
			}
		],
		[
			{
				label: '',
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/developer-enablement/docker/building_%26_publishing_nodejs_image/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Docker Image Building`}
					</a>
				)
			}
		],

		[
			{
				label: '',
				value: (
					<a
						href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/developer-enablement/peripheral-resources/gcs_with_k8s/`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Peripheral Resources Onboarding`}
					</a>
				)
			}
		]
	]

	return (
		<Container header={<Header variant="h3">Access Details</Header>}>
			<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px' }}>
				For accesses to the links below, you may reach out to Platform
				Engineering group (isg-ceso-platengr@globe.com.ph)
			</p>
			<ColumnLayout columns={2} variant="text-grid">
				<ExpandableSection headerText="Resource Links">
					<ColumnLayout columns={2} variant="text-grid">
						<KeyValuePairs
							key={'generalDetails'}
							items={generalDetailsKeyValuePairsItems}
						/>
					</ColumnLayout>
				</ExpandableSection>
				<ExpandableSection headerText="What's Next?">
					<ColumnLayout columns={2} variant="text-grid">
						<KeyValuePairs key={''} items={compassLinkKeyValuePairsItems} />
					</ColumnLayout>
				</ExpandableSection>
			</ColumnLayout>
		</Container>
	)
}

export default AccessDetails
