import { useMutation, useQueryClient } from "@tanstack/react-query"
import { RhocpTenantRequest } from "../../types/rhocpTenant"
import api from "../../api"

const useCreateNewRhocpTenant = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (rhocpTenant: RhocpTenantRequest) => {
      return api.rhocpTenants.createNewRhocpTenant(rhocpTenant)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['rhocpTenants']})
    }
  })
}

export default useCreateNewRhocpTenant