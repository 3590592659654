import CONSTANTS from "../../constants";
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader";
import type { RhocpTenantRequest } from "../../types/rhocpTenant";

const { RHOCPTENANTS_API_URL } = CONSTANTS

const createNewRhocpTenant = async(
  newRhocpTenant: RhocpTenantRequest
): Promise<any> => {
  const response = await fetchWithAuthorizationHeader(`${RHOCPTENANTS_API_URL}`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      action: 'CREATE'
    },
    body: JSON.stringify(newRhocpTenant)
  })

  return response.json()
}

export default createNewRhocpTenant