import React, { FC, ReactNode } from 'react'
import { Control, Controller, UseControllerProps } from 'react-hook-form'
import Checkbox from '@cloudscape-design/components/checkbox'
import { FormField, Input, InputProps } from '@cloudscape-design/components'

interface FormCheckBoxProps {
	label: string
	name: string
	control: Control
	placeholder?: string
	description?: string
	info?: ReactNode
	constraintText?: string
	rules?: UseControllerProps['rules']
	type?: InputProps.Type
	setFormValues: (name: string, value: any) => void
	check_name: string,
	disabled?: boolean
}

const FormCheckBox: FC<FormCheckBoxProps> = ({
	label,
	name,
	control,
	placeholder,
	description,
	info,
	constraintText,
	rules,
	type,
	setFormValues,
	check_name,
	disabled
}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { name, onChange, value }, fieldState: { error } }) => {
				return (
					<FormField
						label={label}
						description={description}
						info={info}
						constraintText={constraintText}
						errorText={error?.message}
					>
						<Checkbox
							onChange={({ detail }) => {
								onChange(detail.checked)
								setFormValues(name, detail.checked)
							}}
							checked={value}
							disabled={disabled}
						>
							{check_name}
						</Checkbox>
					</FormField>
				)
			}}
		/>
	)
}

export default FormCheckBox
