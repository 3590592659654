import { FC, useState } from "react";
import { Form, Container, SpaceBetween, Button, Textarea } from '@cloudscape-design/components';
import { FormProps } from "./FormProps";
import { RhocpTenantRejectionRequest } from "../../../types/rhocpTenant";
import { useOktaAuth } from "@okta/okta-react";
import useRejectRhocpTenant from "../../../hooks/mutate/useRejectRhocpTenant";

export const RejectionForm: FC<FormProps> = ({ showForm, tenantID }) => {
  const { authState } = useOktaAuth()
  const userEmail = authState?.idToken?.claims.email as string

  const [commentText, setCommentText] = useState('')

  const { mutate: rejectRhocpTenant, isLoading } = useRejectRhocpTenant(tenantID)

  const handleReject = () => {
    const rejectionRequest: RhocpTenantRejectionRequest = {
      rhocpTenantID: tenantID,
      commenterID: userEmail,
      comment: commentText,
      action: 'REJECT',
      timestamp: new Date().toISOString()
    }

    if (commentText.length > 0) {
      rejectRhocpTenant(rejectionRequest, {
        onSuccess: () => {
          setCommentText('')
          showForm(false)
        }
      })
    }
  }

  return (
    <Form
      actions={<SpaceBetween direction="horizontal" size="xs">
        <Button variant="link" onClick={() => showForm(false)}>Cancel</Button>
        <Button variant="primary" onClick={handleReject} loading={isLoading}>Reject</Button>
      </SpaceBetween>}>
      <Container>
        <SpaceBetween direction="vertical" size="l">
          <Textarea
            onChange={({ detail }) => setCommentText(detail.value)}
            value={commentText}
            placeholder="Reason for rejection" />
        </SpaceBetween>
      </Container>
    </Form>
  );
};
